/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

/* Modal Container */
.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 380px;
  max-height: 525px;
  /* height: 550px; */
  /* margin-top: 50vh; */
  position: unset;
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Close Button */
.close-btn {
  position: relative;
  top: -10px;
  right: -330px;
  width: 20px;
  height: 20px;
  background-color: red;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-fechar-modal{
  /* display: block; */
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    align-self: right;
    justify-self: right;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    border-radius: .25rem;
    transition: all .15s ease-in-out;
    color: #fff;
    background-color: #868e96;
    border-color: #868e96;
    cursor: pointer;
}

/* Modal Body */
.modal-body {
  margin: 10px 0;
  overflow: auto;
  max-height: 250px;
  max-width: 100%;
  text-align: left;
}

/* Modal Footer */
.modal-footer {
  text-align: right;
  background: #fff;
  border-radius: 5px;
   width: 100%;
  /* max-height: 500px; */
}

.rsrt-tr:hover{
  background: #c21111;
  cursor: pointer;
}

.rsrt-th{
  position: sticky;
  padding: 8px; /* Add padding to the <th> elements */
  font-weight: bold; /* Make the <th> text bold */
}

/* .rsrt-tr{
 overflow-x: auto;
} */




/* @media(max-width:400px) {
  .modal-body {
    max-width: 250px;
  }
  .modal {
    max-width: 250px;
  }
} */